
export function fixNumLen(num, len = 7) {
  return num.toFixed(Math.max(len - Math.floor(num).toString().length, 0))
}

export function fixNumDecimal(num, len = 7) {
  return Math.max(len - Math.floor(num).toString().length, 0)
}

export const round = (n, decimals = 0) => Number(`${Math.round(`${n}e${decimals}`)}e-${decimals}`)

export async function cacheData(cacheKey, duration, callback) {
  // 获取当前时间
  const curTs = Math.floor(new Date().getTime() / 1000)
  // 读取缓存
  let cacheJson = localStorage.getItem(cacheKey)
  let cacheData = JSON.parse(cacheJson)
  if (cacheData && cacheData.expireTs > curTs) {
    return cacheData.data
  }
  // 缓存未命中，读取数据
  let data = await callback()
  cacheData = {
    data: data,
    expireTs: curTs + duration
  }
  // 缓存新数据
  cacheJson = JSON.stringify(cacheData)
  localStorage.setItem(cacheKey, cacheJson)

  return cacheData.data
}

export function getChainConfig(chainId) {
  let chainName, trustApiName, webUrl, mainSymbol, mainDecimal;

  switch (parseInt(chainId)) {
    case 3666:
      chainName = "Joule";
      trustApiName = "joule";
      webUrl = "http://8.210.106.181:8088/";
      mainSymbol = "J";
      mainDecimal = 18;
      break;
    case 534351:
      chainName = "Scroll Sepolia";
      trustApiName = "Scroll Sepolia";
      webUrl = "https://sepolia.scrollscan.com/";
      mainSymbol = "ETH";
      mainDecimal = 18;
      break;
    case 1:
      chainName = "Ethereum";
      trustApiName = "ethereum";
      webUrl = "https://etherscan.io/";
      mainSymbol = "ETH";
      mainDecimal = 18;
      break;
    case 137:
      chainName = "Polygon";
      trustApiName = "polygon";
      webUrl = "https://polygonscan.com/";
      mainSymbol = "MATIC";
      mainDecimal = 18;
      break;
    case 31337:
      chainName = "Localhost";
      trustApiName = "localhost";
      webUrl = "http://localhost:8545/";
      mainSymbol = "ETH";
      mainDecimal = 18;
      break;
    case 200810:
      chainName = "Bitlayer Testnet";
      trustApiName = "Bitlayer Testnet";
      webUrl = "https://testnet-rpc.bitlayer.org";
      mainSymbol = "BTC";
      mainDecimal = 18;
      break;
    case 8453:
      chainName = "Base";
      trustApiName = "Base Layer2";
      webUrl = "https://mainnet.base.org";
      mainSymbol = "ETH";
      mainDecimal = 18;
      break;
    default:
      chainName = "Unknown";
      mainSymbol = "Unknown";
      mainDecimal = 0;
  }

  return { chainId, chainName, trustApiName, webUrl, mainSymbol, mainDecimal };
}
